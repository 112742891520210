import * as React from "react"
import { Link } from "gatsby"
import Header from "../components/sections/header"
import SocialMedia from "../components/sections/SocialMedia"
import Footer from "../components/sections/footer"
import Button from "../components/elements/Button"
import SEO from "../components/seo"

// styles
const pageStyles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

// markup
const NotFoundPage = () => {
    return (
        <>
            <SEO title="404: Not found" />
            <Header />
            <main
                style={pageStyles}>
                <div>
                    <h1>404<br />Seite nicht gefunden</h1>

                    <p>Wir konnten die Seite, die Sie suchen leider nicht finden.</p>

                    <Button type="line" title="zur Startseite" destination="/" />
                </div>
            </main>
            <Footer />
        </>
    )
}

export default NotFoundPage
